import { useCallback, useContext, useState } from 'react';
import { LogoSpinnerFullCentered } from '../../components/LogoSpinner';
import styles from './MobileSoloPortal.module.css';
import {
  CrossIcon,
  PlusIcon,
  SettingsIcon,
  StarDecorations,
  WandIcon,
} from './MobileSoloPortalIcons';
import classNames from '../../helper/classNames';
import { WorkspaceContext } from '../../App';
import { episodeTypes } from 'zync-common/types';
import { MobileSoloEpisodeCard } from './MobileSoloEpisodeCard';
import { MobileLoadingSoloEpisodeCard } from './MobileLoadingSoloEpisodeCard';
import { soloSessionCreationOptions } from '../Portal/NewSoloSessionSelectionModal';
import { soloSessionCreationKeys } from '../Portal/constants';
import { getStaticAssetUrl } from '../../helper/getStaticAssetUrl';
import { logerror } from '../../helper/contextualLogger';
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';
import { MobileCreateNewEventModal } from './MobileCreateNewEventModal';

const upgradeFlag = getStaticAssetUrl('mobile-upgrade-flag.png');
const mobileAlert = getStaticAssetUrl('mobile-alert.png');

const useEpisodeCreationAnimations = ({ setShowEpisodeOptions }) => {
  const [plusButtonAnimation, setPlusButtonAnimation] = useState('');
  const [crossButtonAnimation, setCrossButtonAnimation] = useState('');
  const [episodeOptionsAnimation, setEpisodeOptionsAnimation] = useState('');

  const triggerPlusButtonAnimation = useCallback((mode = 'open') => {
    if (mode === 'open') {
      setPlusButtonAnimation(`${styles['rotate-plus-right']}`);
    } else {
      setPlusButtonAnimation(`${styles['rotate-plus-left']}`);
    }
  }, []);

  const triggerCrossButtonAnimation = useCallback((mode = 'open') => {
    if (mode === 'open') {
      setCrossButtonAnimation(`${styles['rotate-cross-right']}`);
    } else {
      setCrossButtonAnimation(`${styles['rotate-cross-left']}`);
    }
  }, []);

  const triggerShowEpisodeOptionsAnimation = useCallback((mode = 'open') => {
    if (mode === 'open') {
      setEpisodeOptionsAnimation(`${styles['height-animation']}`);
    } else {
      setEpisodeOptionsAnimation('');
    }
  }, []);

  const openMenu = useCallback(() => {
    triggerPlusButtonAnimation();
    triggerShowEpisodeOptionsAnimation();
    setTimeout(() => {
      setPlusButtonAnimation('');
      triggerCrossButtonAnimation();
      setShowEpisodeOptions(true);
    }, 200);
    setTimeout(() => {
      setCrossButtonAnimation('');
    }, 400);
  }, [
    setShowEpisodeOptions,
    triggerCrossButtonAnimation,
    triggerPlusButtonAnimation,
    triggerShowEpisodeOptionsAnimation,
  ]);

  const closeMenu = useCallback(() => {
    triggerCrossButtonAnimation('close');
    triggerShowEpisodeOptionsAnimation('close');
    setTimeout(() => {
      setCrossButtonAnimation('');
      triggerPlusButtonAnimation('close');
      setShowEpisodeOptions(false);
    }, 200);
    setTimeout(() => {
      setPlusButtonAnimation('');
    }, 400);
  }, [
    setShowEpisodeOptions,
    triggerCrossButtonAnimation,
    triggerPlusButtonAnimation,
    triggerShowEpisodeOptionsAnimation,
  ]);

  return {
    plusButtonAnimation,
    crossButtonAnimation,
    episodeOptionsAnimation,
    openMenu,
    closeMenu,
  };
};

const ClipsLeft = () => {
  const { workspace } = useContext(WorkspaceContext);

  const { tierLimits, unlimitedClips } = workspace || {};
  const { isActive: isUnlimitedClipsActive = false } = unlimitedClips || {};

  if (isUnlimitedClipsActive) {
    return null;
  }

  if (!tierLimits) {
    return null;
  }

  const { limitPerMonth, enforceLimit, clipsCurrentMonth } =
    tierLimits?.clips || {};

  if (!enforceLimit) {
    return null;
  }

  const clipsLeft = limitPerMonth - Math.min(clipsCurrentMonth, limitPerMonth);

  return (
    <div className="text-[#7A7A7A] font-medium text-sm px-5 mt-1">
      <span className="text-[#2E2E2E]">{clipsLeft}</span> of{' '}
      <span className="text-[#2E2E2E]">{limitPerMonth}</span> Videos left
    </div>
  );
};

export const MobileSoloPortal = ({
  seriesListLoading,
  brandKit,
  soloSettings,
  currentWorkspaceName,
  workspaceId,
  soloEpisodes,
  plan,
  fetchNewMeetings,
  workspacePlan,
  creatorUserId,
}) => {
  const [showEpisodeOptions, setShowEpisodeOptions] = useState(false);
  const [showSettingsDesktopOnlyModal, setShowSettingsDesktopOnlyModal] =
    useState(false);

  const {
    plusButtonAnimation,
    crossButtonAnimation,
    episodeOptionsAnimation,
    openMenu,
    closeMenu,
  } = useEpisodeCreationAnimations({ setShowEpisodeOptions });

  const defaultSpeaker = soloSettings?.defaultSpeaker;
  const userId = useSelector((st) => st.auth?.user?.userId);

  const [isNewManualSoloSessionModalOpen, setIsNewManualSoloSessionModalOpen] =
    useState(false);

  const [
    isNewManualScriptSoloSessionModalOpen,
    setIsNewManualScriptSoloSessionModalOpen,
  ] = useState(false);

  const [
    isNewBlogPostBasedSoloSessionModalOpen,
    setIsNewBlogPostBasedSoloSessionModalOpen,
  ] = useState(false);

  const [
    isNewIdeaBasedSoloSessionModalOpen,
    setIsNewIdeaBasedSoloSessionModalOpen,
  ] = useState(false);

  return (
    <>
      {seriesListLoading && !brandKit?.primaryColor ? (
        <div className="h-[90vh] grid place-content-center">
          <LogoSpinnerFullCentered />
        </div>
      ) : (
        <div className="flex flex-col min-h-screen h-screen relative">
          <div className="flex flex-col p-3">
            {defaultSpeaker && (
              <div
                className="relative isolate min-h-[143px] max-h-[143px] flex flex-col justify-end rounded-[25px] overflow-hidden p-5 shadow-800"
                style={{
                  backgroundColor: brandKit?.primaryColor,
                  backgroundSize: '40px 40px',
                  backgroundPositionY: '20px',
                }}
              >
                <div className="flex w-full justify-between z-10 items-end">
                  <div className="flex gap-2.5 max-w-[70%] phone-m:max-w-[80%] break-words items-center">
                    {defaultSpeaker?.presenterPictureUrl && (
                      <img
                        src={defaultSpeaker?.presenterPictureUrl}
                        className="max-w-[60px] max-h-[60px] rounded-xl object-contain"
                        alt=""
                      />
                    )}
                    <div
                      className="flex flex-col font-roboto"
                      style={{ color: brandKit.primaryContrast }}
                    >
                      <span className="font-semibold phone-l:text-[22px] text-base">
                        {defaultSpeaker.fullName}
                      </span>
                      {defaultSpeaker?.jobTitle && (
                        <span className="text-[13px]">
                          {defaultSpeaker?.jobTitle}
                        </span>
                      )}
                      <span className="mt-2 text-[11px]">
                        {currentWorkspaceName}
                      </span>
                    </div>
                  </div>
                  <button
                    onClick={() => setShowSettingsDesktopOnlyModal(true)}
                    className="h-min"
                  >
                    <SettingsIcon color={brandKit.accentContrast} />
                  </button>
                </div>
                <div
                  className="absolute h-[75%] aspect-square rounded-full -right-3 top-[-25%]"
                  style={{
                    backgroundColor: brandKit.accentColor,
                  }}
                />
                <div
                  className="absolute h-[15%] aspect-square rounded-full right-16 top-[50%]"
                  style={{
                    backgroundColor: brandKit.accentColor,
                  }}
                />
                <div
                  className="absolute h-[60%] aspect-square rounded-full -right-4 top-[50%]"
                  style={{
                    backgroundColor: brandKit.accentColor,
                  }}
                />
              </div>
            )}
            {seriesListLoading ? (
              <LogoSpinnerFullCentered />
            ) : (
              <>
                <div className="flex flex-col mt-7">
                  <span className="text-[#2E2E2E] font-medium font-roboto px-5">
                    Video Recording Sessions
                  </span>
                  <ClipsLeft />
                  <div className="flex flex-col w-full gap-[2px] mt-4">
                    {soloEpisodes.length &&
                      soloEpisodes.map((series) =>
                        series.settings.episodeType ===
                        episodeTypes.placeholder ? (
                          <MobileLoadingSoloEpisodeCard />
                        ) : (
                          <MobileSoloEpisodeCard
                            key={series.meetingSeriesId}
                            series={series}
                          />
                        )
                      )}
                  </div>
                </div>
              </>
            )}
          </div>
          {!showEpisodeOptions && (
            <div className="fixed z-10 flex justify-center items-center w-full bg-gradient-to-b from-[rgba(255,255,255,0)] to-white h-[130px] bottom-0">
              <button
                className={classNames(
                  'z-10 min-w-[60px] min-h-[60px] max-w-[60px] max-h-[60px] bg-[#5C41FF] rounded-full flex justify-center items-center bottom-8'
                )}
                onClick={() => {
                  openMenu();
                }}
              >
                <div className={classNames(plusButtonAnimation)}>
                  <PlusIcon />
                </div>
              </button>
            </div>
          )}
          <div
            className={classNames(
              `fixed z-10 bg-black max-h-0 overflow-hidden flex flex-col bottom-3 w-[calc(100%-24px)] left-1/2 -translate-x-1/2 rounded-2xl ${styles['episode-options']}`,
              episodeOptionsAnimation
            )}
          >
            <div className="flex flex-col gap-1.5 w-full px-3.5 pt-3.5">
              {soloSessionCreationOptions.map(
                ({
                  aiSuggest,
                  itemKey,
                  mobileIcon,
                  header,
                  mobileHeader,
                  description,
                }) =>
                  plan === 'free' &&
                  itemKey !== soloSessionCreationKeys.manualScript ? (
                    <button
                      key={itemKey}
                      className="h-[83px] bg-[#A6A6A6] w-full rounded-[10px] py-4 pl-[30px] flex relative"
                      disabled
                    >
                      <div className="flex items-center h-full">
                        {mobileIcon}
                      </div>
                      <div className="flex flex-col ml-6 max-w-[180px] w-[180px] text-white font-roboto text-sm text-left justify-center h-full z-10">
                        <span className="font-black uppercase">
                          {mobileHeader || header}
                        </span>
                        <span className="font-medium">{description}</span>
                      </div>
                      <img
                        src={upgradeFlag}
                        alt=""
                        className="absolute top-2 right-0 object-contain w-16"
                      />
                      <div className="absolute top-0 right-0 rounded-[10px] overflow-hidden">
                        <StarDecorations />
                      </div>
                    </button>
                  ) : (
                    <button
                      key={itemKey}
                      className="h-[83px] bg-[#5C41FF] w-full rounded-[10px] py-4 pl-[30px] flex"
                      onClick={() => {
                        if (
                          plan === 'free' &&
                          itemKey !== soloSessionCreationKeys.manualScript
                        ) {
                          return;
                        }
                        closeMenu();
                        switch (itemKey) {
                          case soloSessionCreationKeys.idea: {
                            mixpanel.track(
                              'Mobile Portal: New Session - From Idea',
                              {
                                distinct_id: userId,
                              }
                            );
                            setIsNewIdeaBasedSoloSessionModalOpen(true);
                            break;
                          }

                          case soloSessionCreationKeys.blogPost: {
                            mixpanel.track(
                              'Mobile Portal: New Session - From Content',
                              {
                                distinct_id: userId,
                              }
                            );
                            setIsNewBlogPostBasedSoloSessionModalOpen(true);
                            break;
                          }
                          case soloSessionCreationKeys.manual: {
                            mixpanel.track(
                              'Mobile Portal: New Session - From Prompts',
                              {
                                distinct_id: userId,
                              }
                            );
                            setIsNewManualSoloSessionModalOpen(true);
                            break;
                          }
                          case soloSessionCreationKeys.manualScript: {
                            mixpanel.track(
                              'Mobile Portal: New Session - From Script',
                              {
                                distinct_id: userId,
                              }
                            );
                            setIsNewManualScriptSoloSessionModalOpen(true);
                            break;
                          }
                          default: {
                            logerror({
                              message: `soloSessionCreationKey ${itemKey} not found. Did you add a new solo session creation key but did not update the onConfirm handler?`,
                            });
                          }
                        }
                      }}
                    >
                      <div className="flex items-center h-full">
                        {mobileIcon}
                      </div>
                      <div className="flex flex-col ml-6 max-w-[180px] w-[180px] text-white font-roboto text-sm text-left justify-center h-full z-10">
                        <span className="font-black uppercase">{header}</span>
                        <span className="font-medium">{description}</span>
                      </div>
                      {aiSuggest && (
                        <div className="grow flex justify-end pr-6">
                          <WandIcon />
                        </div>
                      )}
                    </button>
                  )
              )}
            </div>
            <div className="w-full h-[102px] bg-transparent"></div>
          </div>
          {showEpisodeOptions && (
            <>
              <button
                className={classNames(
                  'fixed z-20 min-w-[60px] min-h-[60px] max-w-[60px] max-h-[60px] bg-white rounded-full flex justify-center items-center left-1/2 -translate-x-1/2 bottom-8'
                )}
                onClick={() => {
                  closeMenu();
                }}
              >
                <div className={classNames(crossButtonAnimation)}>
                  <CrossIcon />
                </div>
              </button>
            </>
          )}
        </div>
      )}
      {isNewManualSoloSessionModalOpen && (
        <MobileCreateNewEventModal
          onClose={() => {
            setIsNewManualSoloSessionModalOpen(false);
            fetchNewMeetings();
          }}
          workspaceId={workspaceId}
          workspacePlan={workspacePlan}
          defaultHostId={creatorUserId}
          usePreview={false}
          episodeType={episodeTypes.solo}
        />
      )}
      {isNewManualScriptSoloSessionModalOpen && (
        <MobileCreateNewEventModal
          onClose={() => {
            setIsNewManualScriptSoloSessionModalOpen(false);
            fetchNewMeetings();
          }}
          workspaceId={workspaceId}
          workspacePlan={workspacePlan}
          defaultHostId={creatorUserId}
          usePreview={false}
          episodeType={episodeTypes.soloScriptBased}
        />
      )}
      {isNewBlogPostBasedSoloSessionModalOpen && (
        <MobileCreateNewEventModal
          onClose={() => {
            setIsNewBlogPostBasedSoloSessionModalOpen(false);
            fetchNewMeetings();
          }}
          workspaceId={workspaceId}
          workspacePlan={workspacePlan}
          defaultHostId={creatorUserId}
          usePreview={false}
          episodeType={episodeTypes.soloBlogBased}
        />
      )}
      {isNewIdeaBasedSoloSessionModalOpen && (
        <MobileCreateNewEventModal
          onClose={() => {
            setIsNewIdeaBasedSoloSessionModalOpen(false);
            fetchNewMeetings();
          }}
          workspaceId={workspaceId}
          workspacePlan={workspacePlan}
          defaultHostId={creatorUserId}
          usePreview={false}
          episodeType={episodeTypes.soloIdeaBased}
        />
      )}
      {showSettingsDesktopOnlyModal && (
        <div className="z-50 bg-[rgba(26,26,36,0.85)] min-h-full w-full absolute top-0 backdrop-blur-[25px] flex justify-center items-center">
          <div className="flex flex-col w-full px-9">
            <div className="flex justify-center items-center bg-[#73C384] rounded-t-3xl py-8">
              <img src={mobileAlert} alt="" />
            </div>
            <div className="flex flex-col px-8 bg-white rounded-b-3xl pt-10 pb-8 items-center">
              <span className="text-[#5A5A5A] text-[22px] font-medium font-roboto text-center">
                Settings cannot be adjusted on a mobile browser. Visit this page
                on your laptop browser.
              </span>
              <button
                className="bg-[#5C41FF] text-white font-montserrat font-bold rounded-xl w-[123px] h-12 mt-10"
                onClick={() => setShowSettingsDesktopOnlyModal(false)}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
