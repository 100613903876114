import React, { memo } from 'react';
import { ZyncErrorModal } from '../components/ZyncErrorModal';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';
import { isMobile } from '../helper';
import styles from './page404/page404.module.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const error404CatSrc = getStaticAssetUrl('.404_cat.svg');
const mobile404 = getStaticAssetUrl('mobile-404-art.png');

export const Page404 = memo(() => {
  if (isMobile) {
    return (
      <div
        className={`h-screen max-h-screen max-w-full w-full flex flex-col justify-between items-center p-[10%] ${styles['mobile-container']}`}
      >
        <div className="flex flex-col items-center justify-center h-full">
          <img
            src={mobile404}
            alt=""
            className="max-h-[430px] h-[50vh] object-contain"
          />
          <span className="text-[#3362CC] text-[26px] font-montserrat font-bold">
            Page not found !
          </span>
        </div>
        <Link
          className="font-montserrat font-bold text-white bg-[#5C41FF] rounded-2xl text-xl w-full py-3 text-center"
          to="/"
        >
          Go to Home
        </Link>
      </div>
    );
  }

  return (
    <ZyncErrorModal
      title="Oops! you weren't supposed to see this."
      message="The page you are looking for does not exist."
      imgSrc={error404CatSrc}
    >
      <a
        href="/portal"
        className="font-medium text-purple decoration-purple decoration-1 underline text-base sm:text-2xl"
      >
        Please return to Portal
      </a>
    </ZyncErrorModal>
  );
});
