import { WarningTriangleIcon } from '../../../components/icons/WarningTriangleIcon';
import { getStaticAssetUrl } from '../../../helper/getStaticAssetUrl';
import { DownloadIcon } from '../../../components/icons/DownloadIcon';

export const MobileContentKitView = ({
  title,
  portraitVideoUrl,
  landscapeVideoUrl,
  squareVideoUrl,
}) => {
  return (
    <div className="mx-4 font-roboto">
      <h1 className="bg-[#303364] text-white text-lg -mx-4 px-10 py-4">
        <p className="text-[#CACBDB] font-normal text-[15px] leading-4">
          Title
        </p>
        <p className="text-lg font-medium">{title}</p>
      </h1>
      <div className="px-6 pt-8 pb-4">
        <p className="text-lg font-semibold text-[#303364]">Congratulations</p>
        <p className="text-base font-medium text-[#888AA4]">
          Your Content Kit Is Ready
        </p>
      </div>
      <div className="flex gap-5 text-[#253858] bg-[#FFC1C1] p-4 rounded-2xl border-[#FF4F4F] border-[0.5px] border-dashed">
        <div className="pt-1">
          <WarningTriangleIcon
            className="fill-[#253858]"
            height={26}
            width={26}
          />
        </div>

        <div>
          <p className="font-semibold text-base">
            Partial Content Kit Displayed
          </p>
          <p className="font-medium text-sm">
            For the full content kit experience, please visit this page on a
            desktop browser.
          </p>
        </div>
      </div>

      <div className="flex gap-4 border border-blue-gray/25 rounded-2xl mt-6 p-4 pr-8">
        <div className="w-[50px] flex flex-col justify-center items-center gap-4">
          {squareVideoUrl && (
            <div>
              <img
                alt="Square Video"
                src={getStaticAssetUrl('video-square-icon.jpg')}
                className="h-[40px]"
              />
            </div>
          )}
          {landscapeVideoUrl && (
            <div>
              <img
                alt="Landscape Video"
                src={getStaticAssetUrl('video-landscape-icon.jpg')}
                className="h-[32px]"
              />
            </div>
          )}
          {portraitVideoUrl && (
            <div>
              <img
                alt="Portrait Video"
                src={getStaticAssetUrl('video-portrait-icon.jpg')}
                className="h-[38px]"
              />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-4 w-full">
          {squareVideoUrl && (
            <div className="flex w-full items-center gap-2 justify-between h-[40px]">
              <p>Square Video</p>
              <a href={squareVideoUrl} download="square.mp4" className="p-1">
                <DownloadIcon className="fill-blue-gray" />
              </a>
            </div>
          )}
          {landscapeVideoUrl && (
            <div className="flex w-full items-center gap-2 justify-between h-[32px]">
              <p>Landscape Video</p>
              <a
                href={landscapeVideoUrl}
                download="landscape.mp4"
                className="p-1"
              >
                <DownloadIcon className="fill-blue-gray" />
              </a>
            </div>
          )}
          {portraitVideoUrl && (
            <div className="flex w-full items-center gap-2 justify-between h-[38px]">
              <p>Portrait Video</p>
              <a
                href={portraitVideoUrl}
                download="portrait.mp4"
                className="p-1"
              >
                <DownloadIcon className="fill-blue-gray" />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
