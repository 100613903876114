import React, { useEffect, useRef } from 'react';
import { Modal, ModalWindow, ModalBody, ModalTitle } from './Modal';
import { LogoSpinnerFullCentered, LogoSpinner } from './LogoSpinner';
import { shallowEqual, useSelector } from 'react-redux';
import { logerror } from '../helper/contextualLogger';

export const ZyncLoadingModal = ({
  message,
  hideSpinner = false,
  title,
  size = Modal.size.xs,
  series,
  children,
  doNotLog = false,
}) => {
  const { user, authenticated } = useSelector(
    (state) => state.auth,
    shallowEqual
  );
  const { userId } = user || {};
  const { meetingSeriesId } = series || {};

  const timeout = useRef(null);

  useEffect(() => {
    if (doNotLog) return;
    timeout.current = setTimeout(() => {
      logerror({
        message: 'Zync Loading Modal showing for 10 seconds',
        userId,
        authenticated,
        meetingSeriesId,
      });
    }, 10_000);

    return () => {
      clearTimeout(timeout.current);
    };
  }, [authenticated, doNotLog, meetingSeriesId, userId]);

  return (
    <ModalWindow
      size={size}
      zyncLogo
      showCancel={false}
      boxShadow={Modal.boxShadow['elevation-one']}
    >
      <div className="text-center">
        {title && <ModalTitle>{title}</ModalTitle>}
        <ModalBody>
          {!hideSpinner && (
            <LogoSpinnerFullCentered size={LogoSpinner.size.sm} />
          )}
          {message && <p>{message}</p>}
          {children}
        </ModalBody>
      </div>
    </ModalWindow>
  );
};
