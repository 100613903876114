import React, { useContext, useState } from 'react';
import { WorkspaceContext } from '../../App';
import { LogoSpinnerFullCentered } from '../../components/LogoSpinner';
import { Page404 } from '../page404';
import { AdminProtectedRoute } from '../AdminProtectedRoute';
import { merge } from 'lodash';
import { Switch } from '../../components/common/Switch';
import { updateFeatureFlag } from '../../helper/api';
import { ModalBody, ModalTitle, ModalWindow } from '../../components/Modal';
import { FuturisticBackground } from '../../components/FuturisticBackground';

const standardFeatureFlags = [
  'enablePublicContentKit',
  'useDownloadLocalRecording',
  'useMoreThemes',
  'useEnhancedLanguage',
  'useZyncUp',
  'useSoloStarterPlan',
];

const Page = ({ workspace }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [featureFlags, setFeatureFlags] = useState(workspace.featureFlags);

  const workspaceFeatureFlags = merge(
    featureFlags,
    standardFeatureFlags.reduce((flags, featureFlag) => {
      flags[featureFlag] = featureFlags[featureFlag] || false;
      return flags;
    }, {})
  );

  return (
    <>
      <ModalWindow size="lg" zyncLogo={true} bg="transparent" boxShadow="3xl">
        <ModalTitle>
          <p className="text-3xl font-medium mb-3 text-blue-dark">
            {workspace.name} - Admin Page
          </p>
        </ModalTitle>
        <ModalBody>
          <section>
            <h2 className="text-xl my-3 font-semibold text-blue-dark">
              Feature Flags Management
            </h2>
            <div className="space-y-1 my-3">
              {Object.entries(workspaceFeatureFlags)
                .slice()
                .sort()
                .map(([featureFlag, enabled]) => (
                  <div className="flex gap-2">
                    <span>{featureFlag}</span>
                    <Switch
                      disabled={isSubmitting}
                      checked={enabled}
                      onChangeValue={async (newValue) => {
                        setIsSubmitting(true);
                        try {
                          await updateFeatureFlag(
                            workspace.workspaceId,
                            featureFlag,
                            newValue
                          );
                          setFeatureFlags((prev) => {
                            prev[featureFlag] = newValue;
                            return prev;
                          });
                        } finally {
                          setIsSubmitting(false);
                        }
                      }}
                    />
                  </div>
                ))}
            </div>
          </section>
        </ModalBody>
      </ModalWindow>
      <FuturisticBackground
        topGradientOpacity={FuturisticBackground.topGradientOpacity.LOW}
      />
    </>
  );
};

export const WorkspaceAdminPage = () => {
  const { workspace, isLoading } = useContext(WorkspaceContext);

  if (isLoading) {
    return (
      <div className="h-screen w-screen place-content-center ">
        <LogoSpinnerFullCentered />
      </div>
    );
  }

  if (!workspace?.workspaceId) {
    return <Page404 />;
  }

  return (
    <AdminProtectedRoute>
      <Page workspace={workspace} />
    </AdminProtectedRoute>
  );
};
