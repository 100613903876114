import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from '../../components/LoadingSpinner';

const messages = [
  'Preparing your session.',
  'Generating talking points.',
  'Writing video scripts.',
  'Setting up studio.',
];

const getRandomMessage = () =>
  messages[Math.floor(Math.random() * messages.length)];

let interval;

export const MobileLoadingSoloEpisodeCard = () => {
  const [message, setMessage] = useState(getRandomMessage());

  useEffect(() => {
    interval = setInterval(() => setMessage(getRandomMessage()), 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <article className="bg-[#F0F2F5] border border-[#EDEFF2] rounded-[15px] pl-[25px] pr-[15px] py-4 flex justify-between items-center">
        <div className="flex items-center gap-3">
          <LoadingSpinner width="50px" thickness="3px" />{' '}
          <span className="text-[#888888] font-semibold">{message}</span>
        </div>
      </article>
    </div>
  );
};
