import UIFx from '../helper/UIFx';

export const SOUNDS = {
  rimshot: new UIFx('https://cdn.zync.ai/assets/audio/rimshot.mp3', {
    volume: 0.2,
    throttleMs: 2000,
  }),

  fakenews: new UIFx('https://cdn.zync.ai/assets/audio/fakenews.mp3', {
    volume: 0.2,
    throttleMs: 2000,
  }),
  airhorn: new UIFx('https://cdn.zync.ai/assets/audio/airhorn.mp3', {
    volume: 0.2,
    throttleMs: 2000,
  }),
};
