import React from 'react';
import { TextArea } from '../../components/common/TextArea';
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';

export const AddBlogPost = ({ newBlogPost, setNewBlogPost, onboarding }) => {
  const userId = useSelector((st) => st.auth?.user?.userId);

  return (
    <div className="w-full h-[225px]">
      <TextArea
        placeholder="Please paste content of entire Blog Post/ LinkedIn / Post (minimum 100 chars)"
        className="w-full !h-[80%]"
        value={newBlogPost}
        onChange={setNewBlogPost}
        onBlur={(event) => {
          if (event.target.value) {
            mixpanel.track(
              onboarding
                ? 'Onboarding: Updated Content Style'
                : 'New Session: Content Updated',
              {
                distinct_id: userId,
              }
            );
          }
        }}
        label={<p className="text-center"></p>}
      />
    </div>
  );
};

export const MobileAddBlogPost = ({
  newBlogPost,
  setNewBlogPost,
  onboarding,
}) => {
  const userId = useSelector((st) => st.auth?.user?.userId);

  return (
    <textarea
      placeholder="Please paste content of entire Blog Post/ LinkedIn / Post (minimum 100 chars)"
      className="h-full w-full p-5 rounded-2xl border-[0.5px] border-[#30336480] placeholder-[#E8E8E8] outline-none font-roboto"
      value={newBlogPost}
      onChange={(event) => setNewBlogPost(event.target.value)}
      onBlur={(event) => {
        if (event.target.value) {
          mixpanel.track(
            onboarding
              ? 'Onboarding: Updated Content Style'
              : 'New Session: Content Updated',
            {
              distinct_id: userId,
            }
          );
        }
      }}
    />
  );
};
