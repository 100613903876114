const isDefaultBrandKit = (brandKit) => {
  const { ctaText, font, primaryColor, accentColor, backgroundColor, logoUrl } =
    brandKit || {};

  return [
    font === 'Poppins',
    primaryColor === '#1ABC9C',
    accentColor === '#F1C40F',
    backgroundColor === '#FFFFFF',
    logoUrl === 'https://cdn.zync.ai/assets/static/default_logo.png',
    ctaText === 'Powered by Zync',
  ].every(Boolean);
};

module.exports = {
  isDefaultBrandKit,
};
