import React, { useEffect, useRef, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { isMobile, makeRandomId } from '../helper';
import { useUserWorkspaces } from '../hooks/useUserWorkspaces';
import classNames from '../helper/classNames';
import { fromError, logerror } from '../helper/contextualLogger';
import { LogoSpinnerFullCentered } from '../components/LogoSpinner';
import { FuturisticBackground } from '../components/FuturisticBackground';
import { usePosterSlide } from '../components/series/settings/usePosterSlide';
import { ScenePreview } from '../components/authoring/Menu/ScenePreview';
import { WelcomeUserProfile } from './Welcome/WelcomeUserProfile';
import { WelcomeBrandKitForm } from './Welcome/WelcomeBrandKitForm';
import {
  createNewSeriesApi,
  generateEventPosterInBackgroundApi,
} from '../helper/api';
import { SeriesVisibility } from '../helper/visibility';
import { useInterviewMeetingTemplate } from './Portal/useInterviewMeetingTemplate';
import {
  createNewPresenterSlide,
  createNewQuestionSlide,
} from './Portal/useMeetingCreator';
import { presenterTypes } from '../components/series/settings/EventPresenters';
import { getLocalTimezoneName, halfOurFromNow } from '../helper/rsvp';
import moment from 'moment';
import {
  DEFAULT_POSTER_PLACEHOLDERS,
  defaultBrandkit,
} from '../helper/constants';
import { useElementSize } from '../hooks/useElementSize';
import { episodeBuilder } from '../helper/episodeBuilder';
import { ProgressBar } from '../components/onboarding/ProgressBar';
import { StageArrows } from '../components/onboarding/StageArrows';
import { buttonFunctionalityClasses } from '../components/onboarding/constants';
import { FormSteps } from '../components/onboarding/FormSteps';

const checkpointNames = {
  INTRO_VIDEO: 'INTRO_VIDEO',
  CHOOSE_WORKSPACE: 'CHOOSE_WORKSPACE',
  SETUP_PROFILE: 'SETUP_PROFILE',
  SETUP_BRANDKIT: 'SETUP_BRANDKIT',
  SHOW_DESCRIPTION: 'SHOW_DESCRIPTION',
  SHOW_AUDIENCE: 'SHOW_AUDIENCE',
};

const getScheduledEventOneDayFromNow = () => {
  const now = moment();
  const oneDayFromNow = now
    .add(1, 'day')
    .set('hour', 12 + Math.floor(Math.random() * 4))
    .set('minute', 0);
  const startDate = oneDayFromNow.toISOString();
  const endDate = halfOurFromNow(startDate);
  const timezone = getLocalTimezoneName();

  return {
    startDate,
    endDate,
    timezone,
  };
};

export const createPortalStarterKit = async (
  workspaceId,
  template,
  user,
  questionSlides = [],
  onboardingCompleted = false
) => {
  try {
    const { userName, bio, avatarUrl, emailAddress } = user || {};

    const eventHost = {
      emailAddress: emailAddress,
      fullName: userName,
      jobTitle: bio || '',
      linkedInUrl: '',
      presenterPictureUrl: avatarUrl || '',
      type: presenterTypes.HOST,
    };

    const arjunSpeaker = {
      emailAddress: 'arjun@zync.ai',
      fullName: 'Arjun Sundararajan',
      jobTitle: 'CEO Zync AI. The Talkshow Talk: Behind the Mic with Hosts',
      linkedInUrl: '',
      presenterPictureUrl:
        'https://cdn.zync.ai/media/user/arjun@zync.ai/arjun.jpeg',
      type: presenterTypes.SPEAKER,
    };

    const eventPresenters = [arjunSpeaker];
    const eventPresenter =
      eventPresenters[Math.floor(Math.random() * eventPresenters.length)];

    const scheduledSeriesTitle =
      '[TRIAL EPISODE] Launching your Show like a Pro: Insights from the Zync Team';

    const { init, build } = episodeBuilder({
      title: scheduledSeriesTitle,
      slides: [
        {
          sceneTemplateKey: 'scene_welcome_1x1',
        },
        {
          sceneTemplateKey: 'scene_speaker_intro_2',
          mapper: (scene) => createNewPresenterSlide(scene, eventPresenter),
        },
        ...questionSlides.map((questionSlide) => ({
          sceneTemplateKey: 'scene_discussion_point',
          mapper: (scene) => createNewQuestionSlide(scene, questionSlide),
        })),
        { sceneTemplateKey: 'scene_q&a' },
        {
          sceneTemplateKey: 'scene_opinionpoll_host',
        },
        {
          sceneTemplateKey: 'scene_wordcloud_host',
        },
        {
          sceneTemplateKey: 'scene_thankyou',
        },
      ],
      templateKey: 'trial_episode_template',
      scheduledEvent: getScheduledEventOneDayFromNow(),
      options: {
        eventPresenters: [eventPresenter, eventHost],
      },
    });

    await init();

    const scheduledSeries = await createNewSeriesApi(
      workspaceId,
      emailAddress,
      scheduledSeriesTitle,
      '',
      SeriesVisibility.RSVP,
      build(),
      getScheduledEventOneDayFromNow(),
      undefined,
      makeRandomId(8),
      onboardingCompleted
    );

    generateEventPosterInBackgroundApi(scheduledSeries.meetingSeriesId);
  } catch (error) {
    logerror(fromError(error));
  }
};

const markOnboardingCompleted = (userId) => {
  try {
    localStorage.setItem('onboardingCompleted-' + userId, 'true');
  } catch (error) {}
};

export const checkIsOnboardingCompleted = (userId) => {
  try {
    const onboardingCompleted = localStorage.getItem(
      'onboardingCompleted-' + userId
    );
    return onboardingCompleted === 'true';
  } catch (error) {
    return true;
  }
};

export const isNewUserOnboarded = (user) => {
  const isOnboarded = checkIsOnboardingCompleted(user.userId);
  const isProfileSetupPending = user.bio === null || user.avatarUrl === null;

  return !isOnboarded && isProfileSetupPending;
};

const WelcomeDirect = () => {
  const user = useSelector((_st) => _st.auth.user, shallowEqual);
  const { template } = useInterviewMeetingTemplate();
  const { userId, userPreferences, userName } = user;
  const firstName = userName.split(' ')[0];

  const checkpoints = {
    [checkpointNames.CHOOSE_WORKSPACE]: {
      heading: 'Show name',
      topics: [
        'What do you want to call your show?',
        'You can change the show name later',
      ],
    },
    [checkpointNames.SHOW_DESCRIPTION]: {
      heading: 'Show description',
      topics: ['About', 'What is this show about?'],
    },
    [checkpointNames.SHOW_AUDIENCE]: {
      heading: 'Show audience',
      topics: ['Your Audience', 'Who should tune in to this show?'],
    },
    [checkpointNames.SETUP_BRANDKIT]: {
      heading: 'Setup Brand Kit',
      topics: [
        'Setup your Brand Kit',
        'You can adjust these settings in your Workspace Settings Later',
      ],
    },
  };

  const [checkpointsData] = useState(checkpoints);
  const [CHOOSE_WORKSPACE, SHOW_DESCRIPTION, SHOW_AUDIENCE, SETUP_BRANDKIT] = [
    checkpointsData[checkpointNames.CHOOSE_WORKSPACE],
    checkpointsData[checkpointNames.SHOW_DESCRIPTION],
    checkpointsData[checkpointNames.SHOW_AUDIENCE],
    checkpointsData[checkpointNames.SETUP_BRANDKIT],
  ];

  const history = useHistory();
  const { createWorkspace } = useUserWorkspaces();
  const [checkpoint, setCheckpoint] = useState(CHOOSE_WORKSPACE);
  const [workspaceName, setWorkspaceName] = useState('');
  const workspaceNameSetRef = useRef(false);
  const [showDescription, setShowDescription] = useState('');
  const [audienceDescription, setAudienceDescription] = useState('');
  const [redirectToDesigner, setRedirectToDesigner] = useState(false);
  const [autoCreatedMeetingSeriesId, setAutoCreatedMeetingSeriesId] =
    useState('');
  const [nextButtonStyling, setNextButtonStyling] = useState(
    buttonFunctionalityClasses.ENABLE
  );
  const [previousButtonStyling, setPreviousButtonStyling] = useState(
    buttonFunctionalityClasses.DISABLE
  );
  const [showInputError, setShowInputError] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [brandKit, setBrandKit] = useState(defaultBrandkit);
  const { width: posterSlideContainerWidth, ref: posterSlideContainerRef } =
    useElementSize();

  useEffect(() => {
    if (!workspaceName && !workspaceNameSetRef.current && firstName) {
      workspaceNameSetRef.current = true;
      setWorkspaceName(`${firstName}'s Show`);
    }
  }, [workspaceName, firstName]);

  const maxInputCharacters = 32;

  const isShortWorkspaceName = !workspaceName.length;
  const isLongWorkspaceName = workspaceName.length > maxInputCharacters;
  const isInvalidWorkspaceName = isShortWorkspaceName || isLongWorkspaceName;

  const styleToDisableNextButton = () => {
    setPreviousButtonStyling(buttonFunctionalityClasses.DISABLE);
    setNextButtonStyling(buttonFunctionalityClasses.DISABLE);
  };

  const styleToEnableNextButton = () => {
    setPreviousButtonStyling(buttonFunctionalityClasses.DISABLE);
    setNextButtonStyling(buttonFunctionalityClasses.ENABLE);
  };

  const styleToEnablePreviousButtonAndNextButton = () => {
    setPreviousButtonStyling(buttonFunctionalityClasses.ENABLE);
    setNextButtonStyling(buttonFunctionalityClasses.ENABLE);
  };

  /** Previous and Next button styling. */
  useEffect(() => {
    switch (checkpoint) {
      case CHOOSE_WORKSPACE:
        if (isInvalidWorkspaceName) {
          styleToDisableNextButton();
        } else {
          styleToEnableNextButton();
        }
        break;
      default:
        styleToEnablePreviousButtonAndNextButton();
        break;
    }
  }, [workspaceName, checkpoint, CHOOSE_WORKSPACE, isInvalidWorkspaceName]);

  /** Workspace Input error handling. */
  useEffect(() => {
    if (checkpoint === CHOOSE_WORKSPACE) {
      if (isShortWorkspaceName) {
        return;
      } else if (isLongWorkspaceName) {
        return;
      }
    }
    setShowInputError(false);
    setInputErrorMessage('');
  }, [
    workspaceName,
    checkpoint,
    CHOOSE_WORKSPACE,
    isShortWorkspaceName,
    isLongWorkspaceName,
  ]);

  const handleChangeWorkspaceName = (event) => {
    const value = event.target.value;

    if (!value) {
      setShowInputError(true);
      setInputErrorMessage('Please enter a name for your show.');
    } else if (value.length > 32) {
      setShowInputError(true);
      setInputErrorMessage('The show name cannot exceed 32 characters.');
    }
    setWorkspaceName(event.target.value);
  };

  const setPreviousCheckpoint = (currentCheckpoint = checkpoint) => {
    switch (currentCheckpoint) {
      case SHOW_DESCRIPTION: {
        setCheckpoint(CHOOSE_WORKSPACE);
        break;
      }
      case SHOW_AUDIENCE: {
        setCheckpoint(SHOW_DESCRIPTION);
        break;
      }
      case SETUP_BRANDKIT: {
        setCheckpoint(SHOW_AUDIENCE);
        break;
      }
      default:
    }
  };

  const setNextCheckpoint = (currentCheckpoint = checkpoint) => {
    switch (currentCheckpoint) {
      case CHOOSE_WORKSPACE:
        setCheckpoint(SHOW_DESCRIPTION);
        break;
      case SHOW_DESCRIPTION:
        setCheckpoint(SHOW_AUDIENCE);
        break;
      case SHOW_AUDIENCE:
        setCheckpoint(SETUP_BRANDKIT);
        break;
      default:
    }
  };

  const handlePreviousClick = () => {
    // If the DISABLE class is applied, do not allow previous click.
    if (previousButtonStyling === buttonFunctionalityClasses.DISABLE) {
      return;
    }
    setPreviousCheckpoint();
  };

  const handleNextClick = () => {
    // If the DISABLE class is applied, do not allow next click.
    if (nextButtonStyling === buttonFunctionalityClasses.DISABLE) {
      return;
    }
    if (checkpoint === SETUP_BRANDKIT) {
      setIsLoading(true);
      createWorkspaceAndUpdateUser();
    } else {
      setNextCheckpoint();
    }
  };

  const createWorkspaceAndUpdateUser = async () => {
    try {
      if (userId) {
        const { workspace, autoCreatedMeeting } = await createWorkspace(
          workspaceName,
          userId,
          {
            brandKit: brandKit.brandKit,
            showDescription,
            audienceDescription,
          }
        );

        const workspaceId = workspace.workspaceId;
        const workspaceUrl = `/portal?workspaceId=${workspaceId}`;

        if (
          userPreferences?.quickStartOnboardingTemplateKey &&
          autoCreatedMeeting
        ) {
          setRedirectToDesigner(true);
          setAutoCreatedMeetingSeriesId(autoCreatedMeeting.meetingSeriesId);
        } else {
          markOnboardingCompleted(userId);
          await createPortalStarterKit(
            workspaceId,
            template,
            user,
            [
              {
                text: 'What is your favorite book?',
                id: makeRandomId(6),
                timestamp: Date.now(),
                asker: user.userName,
                moderatorQuestion: true,
                AIGenerated: false,
                index: 0,
              },
            ],
            true
          );
          history.push(workspaceUrl);
        }
      }
    } catch (err) {
      setIsLoading(false);
      logerror({
        message: `Error attempting to call either createWorkspace or updateUserApi from the Welcome page. ${err.message}`,
        stacktrace: err.stack | err,
      });
    }
    setIsLoading(false);
  };

  /** Returns true/false depending on if the checkpoint in state comes before the argument, checkpoint. */
  const isBeforeCheckpoint = (currentCheckpointName) => {
    const currentCheckpoint = checkpointsData[currentCheckpointName];
    const stateCheckpoint = checkpoint;
    if (currentCheckpoint === stateCheckpoint) {
      return false;
    }
    for (let checkpoint in checkpointsData) {
      // found the state checkpoint first
      if (stateCheckpoint === checkpointsData[checkpoint]) {
        return true;
      }
      // found the current checkpoint first
      if (currentCheckpoint === checkpointsData[checkpoint]) {
        return false;
      }
    }
  };

  /** Returns true/false depending on if the checkpoint in state is at the argument, checkpoint. */
  const isAtCheckpoint = (currentCheckpointName) => {
    const currentCheckpoint = checkpointsData[currentCheckpointName];
    return currentCheckpoint === checkpoint;
  };

  const ProgressStates = {
    BEFORE: 'BEFORE',
    CURRENT: 'CURRENT',
    AFTER: 'AFTER',
  };

  /** Every checkpoint is looped over to determine its relationship to the checkpoint in state.
   *  This is used to determine the color & attributes that the currentCheckpoint should contain.
   *  If the checkpoint in state comes before currentCheckpoint, then its state is BEFORE
   *  If the checkpoint in state is at currentCheckpoint, then its state is CURRENT
   *  If the checkpoint in state is after currentCheckpoint, then its state is AFTER
   */
  const getProgressState = (currentCheckpoint) => {
    const isBefore = isBeforeCheckpoint(currentCheckpoint);
    const isAt = isAtCheckpoint(currentCheckpoint);
    if (isBefore) {
      return ProgressStates.BEFORE;
    }
    if (isAt) {
      return ProgressStates.CURRENT;
    }
    return ProgressStates.AFTER;
  };

  const { posterSlide } = usePosterSlide(DEFAULT_POSTER_PLACEHOLDERS);

  if (isLoading) {
    return (
      <div className="w-full h-full grid place-content-center">
        <LogoSpinnerFullCentered />
      </div>
    );
  }
  if (redirectToDesigner && autoCreatedMeetingSeriesId) {
    return <Redirect to={`/e/${autoCreatedMeetingSeriesId}/designer`} />;
  }
  return (
    <div className="w-screen md:h-screen flex justify-center items-center h-fit md:py-0 py-8">
      <FuturisticBackground />
      <div className="z-50 flex flex-col relative w-[calc(100%-34px)] mx-auto md:min-w-[800px] md:max-w-[1000px] bg-white px-1.5 md:px-0 shadow-800 rounded-xl">
        <div className="bg-white flex flex-col justify-center items-center min-h-[24px] md:min-h-[52px] mt-[34px] mb-5 md:mt-8 md:mb-8 space-y-3 md:space-y-2 md:mx-0 mx-8">
          <span className="text-base md:text-xl font-semibold md:font-bold text-blue-dark text-center md:text-left">
            {checkpoint?.topics[0]}
            {checkpoint === SHOW_DESCRIPTION && ` ${workspaceName}`}
          </span>
          {checkpoint?.topics[1] && (
            <span className="text-xs md:text-md font-medium md:font-bold text-blue-gray md:text-light-gray md:mt-4 text-center md:text-left">
              {checkpoint?.topics[1]}
            </span>
          )}
        </div>

        {/* Data */}
        <div className="bg-white flex justify-between items-center md:px-3 space-x-2">
          <div className="flex bg-purple bg-opacity-[5%] grow min-h-[82px] md:min-h-[250px] md:mx-4 rounded-md justify-between items-center">
            {checkpoint === CHOOSE_WORKSPACE && (
              <div className="flex flex-col min-w-full items-center md:space-y-2 px-5 md:px-0 py-5 md:pt-4 md:pb-0">
                <input
                  className={classNames(
                    'w-full md:w-1/2 p-3 border-solid border box-border border-gray rounded-md md:text-base text-sm',
                    showInputError
                      ? 'focus:border-red focus:outline-none outline-red'
                      : 'focus:outline-none focus:border-purple outline-purple'
                  )}
                  placeholder={`${firstName}'s Show`}
                  onChange={handleChangeWorkspaceName}
                  value={workspaceName}
                />
                {showInputError && (
                  <span className="text-xs text-[#D14343] font-bold h-2 text-center md:text-left">
                    {inputErrorMessage}
                  </span>
                )}
              </div>
            )}

            {checkpoint === SHOW_DESCRIPTION && (
              <div className="flex flex-col min-w-full items-center md:space-y-2 px-5 md:px-0 py-5 md:pt-4 md:pb-0">
                <textarea
                  className={classNames(
                    'w-full md:w-1/2 p-3 border-solid border box-border border-gray rounded-md md:text-base text-sm resize-none',
                    showInputError
                      ? 'focus:border-red focus:outline-none outline-red'
                      : 'focus:outline-none focus:border-purple outline-purple'
                  )}
                  maxLength={1000}
                  placeholder={'How would you describe this show to someone?'}
                  onChange={(event) => setShowDescription(event.target.value)}
                  value={showDescription}
                  rows={3}
                ></textarea>
                {showInputError && (
                  <span className="text-xs text-[#D14343] font-bold h-2 text-center md:text-left">
                    {inputErrorMessage}
                  </span>
                )}
              </div>
            )}

            {checkpoint === SHOW_AUDIENCE && (
              <div className="flex flex-col min-w-full items-center md:space-y-2 px-5 md:px-0 py-5 md:pt-4 md:pb-0">
                <textarea
                  className={classNames(
                    'w-full md:w-1/2 p-3 border-solid border box-border border-gray rounded-md md:text-base text-sm resize-none',
                    showInputError
                      ? 'focus:border-red focus:outline-none outline-red'
                      : 'focus:outline-none focus:border-purple outline-purple'
                  )}
                  maxLength={1000}
                  placeholder={'Who should tune in to this show?'}
                  onChange={(event) =>
                    setAudienceDescription(event.target.value)
                  }
                  value={audienceDescription}
                  rows={3}
                />
                {showInputError && (
                  <span className="text-xs text-[#D14343] font-bold h-2 text-center md:text-left">
                    {inputErrorMessage}
                  </span>
                )}
              </div>
            )}
            {checkpoint === SETUP_BRANDKIT && (
              <div className="flex gap-6 md:justify-between w-full mx-5 items-center md:flex-row flex-col-reverse max-w-full min-w-0">
                <WelcomeBrandKitForm
                  workspace={defaultBrandkit}
                  onBrandKitChange={setBrandKit}
                />
                {posterSlide && (
                  <div
                    ref={posterSlideContainerRef}
                    className="lg:w-[600px] md:w-[400px] w-full md:mt-0 mt-5"
                  >
                    <div className="w-fit mx-auto md:ml-auto">
                      <ScenePreview
                        scene={posterSlide}
                        width={
                          isMobile
                            ? 300
                            : posterSlideContainerWidth
                            ? posterSlideContainerWidth
                            : 300
                        }
                        height={
                          isMobile
                            ? (300 / 16) * 9
                            : posterSlideContainerWidth
                            ? (posterSlideContainerWidth / 16) * 9
                            : (300 / 16) * 9
                        }
                        brandKit={brandKit.brandKit}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <FormSteps
          getProgressState={getProgressState}
          checkpointsData={checkpointsData}
          ProgressStates={ProgressStates}
        />
        <StageArrows
          previousButtonStyling={previousButtonStyling}
          nextButtonStyling={nextButtonStyling}
          handleNextClick={handleNextClick}
          handlePreviousClick={handlePreviousClick}
        />
        <ProgressBar
          checkpointsData={checkpointsData}
          getProgressState={getProgressState}
        />
      </div>
    </div>
  );
};

const WelcomeInvite = () => {
  const checkpoints = {
    [checkpointNames.SETUP_PROFILE]: {
      heading: 'Setup User Profile',
      topics: [
        'Setup your profile',
        'You can adjust these values in your Profile settings later',
      ],
    },
  };

  const userId = useSelector((_st) => _st.auth.user?.userId);
  const { width: posterSlideContainerWidth, ref: posterSlideContainerRef } =
    useElementSize();
  const [checkpointsData] = useState(checkpoints);
  const [SETUP_PROFILE] = [checkpointsData[checkpointNames.SETUP_PROFILE]];

  const history = useHistory();
  const [checkpoint] = useState(SETUP_PROFILE);
  const [workspaceName] = useState('');
  const [redirectToDesigner] = useState(false);
  const [autoCreatedMeetingSeriesId] = useState('');
  const [nextButtonStyling, setNextButtonStyling] = useState(
    buttonFunctionalityClasses.ENABLE
  );
  const [previousButtonStyling, setPreviousButtonStyling] = useState(
    buttonFunctionalityClasses.DISABLE
  );

  const [isLoading, setIsLoading] = useState(false);
  const [brandKit, setBrandKit] = useState(defaultBrandkit);

  const { userWorkspaces } = useUserWorkspaces();

  const firstWorkspace = userWorkspaces[0];
  useEffect(() => {
    if (firstWorkspace) {
      setBrandKit(firstWorkspace);
    }
  }, [firstWorkspace]);

  const maxInputCharacters = 32;

  const isShortWorkspaceName = !workspaceName.length;
  const isLongWorkspaceName = workspaceName.length > maxInputCharacters;
  const isInvalidWorkspaceName = isShortWorkspaceName || isLongWorkspaceName;

  const styleToDisablePreviousButton = () => {
    setPreviousButtonStyling(buttonFunctionalityClasses.DISABLE);
  };

  const styleToEnableNextButton = () => {
    setNextButtonStyling(buttonFunctionalityClasses.ENABLE);
  };

  /** Previous and Next button styling. */
  useEffect(() => {
    switch (checkpoint) {
      default:
        styleToDisablePreviousButton();
        styleToEnableNextButton();
        break;
    }
  }, [workspaceName, checkpoint, isInvalidWorkspaceName]);

  const setPreviousCheckpoint = (currentCheckpoint = checkpoint) => {
    switch (currentCheckpoint) {
      default:
    }
  };

  const setNextCheckpoint = (currentCheckpoint = checkpoint) => {
    switch (currentCheckpoint) {
      default: {
      }
    }
  };

  const handlePreviousClick = () => {
    // If the DISABLE class is applied, do not allow previous click.
    if (previousButtonStyling === buttonFunctionalityClasses.DISABLE) {
      return;
    }
    setPreviousCheckpoint();
  };

  const handleNextClick = () => {
    // If the DISABLE class is applied, do not allow next click.
    if (nextButtonStyling === buttonFunctionalityClasses.DISABLE) {
      return;
    }
    if (checkpoint === SETUP_PROFILE) {
      setIsLoading(true);
      markOnboardingCompleted(userId);
      history.push('/portal');
    } else {
      setNextCheckpoint();
    }
  };

  /** Returns true/false depending on if the checkpoint in state comes before the argument, checkpoint. */
  const isBeforeCheckpoint = (currentCheckpointName) => {
    const currentCheckpoint = checkpointsData[currentCheckpointName];
    const stateCheckpoint = checkpoint;
    if (currentCheckpoint === stateCheckpoint) {
      return false;
    }
    for (let checkpoint in checkpointsData) {
      // found the state checkpoint first
      if (stateCheckpoint === checkpointsData[checkpoint]) {
        return true;
      }
      // found the current checkpoint first
      if (currentCheckpoint === checkpointsData[checkpoint]) {
        return false;
      }
    }
  };

  /** Returns true/false depending on if the checkpoint in state is at the argument, checkpoint. */
  const isAtCheckpoint = (currentCheckpointName) => {
    const currentCheckpoint = checkpointsData[currentCheckpointName];
    return currentCheckpoint === checkpoint;
  };

  const ProgressStates = {
    BEFORE: 'BEFORE',
    CURRENT: 'CURRENT',
    AFTER: 'AFTER',
  };

  /** Every checkpoint is looped over to determine its relationship to the checkpoint in state.
   *  This is used to determine the color & attributes that the currentCheckpoint should contain.
   *  If the checkpoint in state comes before currentCheckpoint, then its state is BEFORE
   *  If the checkpoint in state is at currentCheckpoint, then its state is CURRENT
   *  If the checkpoint in state is after currentCheckpoint, then its state is AFTER
   */
  const getProgressState = (currentCheckpoint) => {
    const isBefore = isBeforeCheckpoint(currentCheckpoint);
    const isAt = isAtCheckpoint(currentCheckpoint);
    if (isBefore) {
      return ProgressStates.BEFORE;
    }
    if (isAt) {
      return ProgressStates.CURRENT;
    }
    return ProgressStates.AFTER;
  };

  const { posterSlide } = usePosterSlide(
    DEFAULT_POSTER_PLACEHOLDERS,
    null,
    true,
    'user_profile_preview'
  );

  if (isLoading) {
    return (
      <div className="w-full h-full grid place-content-center">
        <LogoSpinnerFullCentered />
      </div>
    );
  }
  if (redirectToDesigner && autoCreatedMeetingSeriesId) {
    return <Redirect to={`/e/${autoCreatedMeetingSeriesId}/designer`} />;
  }
  return (
    <div className="w-screen md:h-screen">
      <FuturisticBackground />
      <div className="z-50 flex flex-col absolute w-[calc(100%-34px)] mx-auto md:min-w-[800px] md:max-w-[1000px] md:top-1/2 left-1/2 -translate-x-1/2 md:-translate-y-1/2 bg-white px-1.5 md:px-0 shadow-800 rounded-xl top-8">
        <div className="bg-white flex flex-col justify-center items-center min-h-[52px] my-6 md:my-8 space-y-3 md:space-y-2">
          <span className="text-base md:text-xl font-semibold md:font-bold text-blue-dark text-center md:text-left">
            {checkpoint?.topics[0]}
          </span>
          {checkpoint?.topics[1] && (
            <span className="text-xs md:text-md font-medium md:font-bold text-blue-gray md:text-light-gray text-center md:text-left">
              {checkpoint?.topics[1]}
            </span>
          )}
        </div>

        {/* Data */}
        <div className="bg-white flex justify-between items-center md:px-3 space-x-2">
          <div className="flex bg-purple bg-opacity-[5%] grow min-h-[153px] md:min-h-[250px] mx-2 md:mx-4 md:mb-5 rounded-md justify-between items-center">
            {checkpoint === SETUP_PROFILE && (
              <div
                ref={isMobile ? posterSlideContainerRef : undefined}
                className="flex gap-6 w-full mx-5 md:my-0 my-5 md:flex-row flex-col-reverse md:items-start items-center max-w-full min-w-0"
              >
                <WelcomeUserProfile />
                {posterSlide && (
                  <div
                    ref={isMobile ? undefined : posterSlideContainerRef}
                    className="md:w-[400px] w-full"
                  >
                    <ScenePreview
                      scene={posterSlide}
                      width={
                        posterSlideContainerWidth
                          ? posterSlideContainerWidth
                          : 200
                      }
                      height={
                        posterSlideContainerWidth
                          ? (posterSlideContainerWidth / 16) * 9
                          : (200 / 16) * 9
                      }
                      brandKit={brandKit.brandKit}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <FormSteps
          getProgressState={getProgressState}
          checkpointsData={checkpointsData}
          ProgressStates={ProgressStates}
        />
        <StageArrows
          previousButtonStyling={previousButtonStyling}
          nextButtonStyling={nextButtonStyling}
          handleNextClick={handleNextClick}
          handlePreviousClick={handlePreviousClick}
        />
        <ProgressBar
          checkpointsData={checkpointsData}
          getProgressState={getProgressState}
        />
      </div>
    </div>
  );
};

const Welcome = () => {
  const user = useSelector((state) => state.auth?.user, shallowEqual);
  const params = new URLSearchParams(window.location.search);
  const [isNewUser, setIsNewUser] = useState(false);
  const isInitialized = useRef(false);

  useEffect(() => {
    if (user && !isInitialized.current) {
      isInitialized.current = true;
      setIsNewUser(isNewUserOnboarded(user));
    }
  }, [user]);

  switch (true) {
    case params.get('invite') === 'true' || isNewUser: {
      return <WelcomeInvite />;
    }
    default: {
      return <WelcomeDirect />;
    }
  }
};
export default Welcome;
