import { LogoSpinnerFullCentered } from '../../components/LogoSpinner';
import classNames from '../../helper/classNames';
import { Modal, ModalWindow } from '../../components/Modal';
import { useCreateNewEvent as fnCreateNewEvent } from '../Portal/useCreateNewEvent';
import { useCreateNewSoloInterview as fnCreateNewSoloInterview } from '../Portal/useCreateNewSoloInterview';
import { useCreateNewSoloInterviewVideoScript as fnCreateNewSoloInterviewVideoScript } from '../Portal/useCreateNewSoloInterviewVideoScript';
import { useCreateNewBlogBasedSoloInterview as fnCreateNewBlogBasedSoloInterview } from '../Portal/useCreateNewBlogBasedSoloInterview';
import { useCreateNewProfileBasedSoloInterview as fnCreateProfileBasedSoloInterview } from '../Portal/useCreateNewProfileBasedSoloInterview';
import { useCreateNewIdeaBasedSoloInterview as fnCreateNewIdeaBasedSoloInterview } from '../Portal/useCreateNewIdeaBasedSoloInterview';
import {
  MEETING_TOPIC,
  MEETING_TOPIC_SCRIPT,
  MEETING_VIDEO_SCRIPT,
  ProgressStates,
  SET_BLOG_POST,
  SET_IDEA,
} from '../Portal/constants';
import { MobileMeetingTopic } from '../Portal/MeetingTopic';
import {
  emptyScheduledEvent,
  getScheduledEvent,
  useMeetingCreator,
} from '../Portal/useMeetingCreator';
import { useInterviewMeetingTemplate } from '../Portal/useInterviewMeetingTemplate';
import { usePosterSlide } from '../../components/series/settings/usePosterSlide';
import { ScenePreview } from '../../components/authoring/Menu/ScenePreview';
import { episodeTypes } from 'zync-common/types';
import React, { useEffect } from 'react';
import { MobileAddBlogPost } from '../Portal/AddBlogPost';
import { isEqual } from 'lodash';
import { MobileAddIdea } from '../Portal/AddIdea';
import { MobileMeetingVideoScript } from '../Portal/MeetingVideoScript';
import {
  CheckMarkIcon,
  DoubleArrowsIcon,
  LeftArrow,
} from './MobileSoloPortalIcons';
import { buttonFunctionalityClasses } from '../../components/onboarding/constants';

export const getSlideTemplateKey = (eventPresentersCount) => {
  const countToTemplateKeyMap = {
    0: 'scene_poster_interview_1x0',
    1: 'scene_poster_interview_1x1',
    2: 'scene_poster_interview_1x2',
    3: 'scene_poster_interview_1x3',
    4: 'scene_poster_interview_1x4',
    5: 'scene_poster_interview_1x5',
    6: 'scene_poster_interview_1x6',
  };

  return countToTemplateKeyMap[eventPresentersCount];
};
export const MobileCreateNewEventModal = ({
  onClose,
  workspaceId,
  workspacePlan,
  defaultHostId,
  usePreview = true,
  episodeType = episodeTypes.default,
}) => {
  const { template } = useInterviewMeetingTemplate();

  const {
    title,
    setTitle,
    videoScript,
    setVideoScript,

    eventHost,

    eventPresenters,
    temporaryEventPresenter,

    scheduledEvent,

    createNewEvent,
    isCreatingWithDate,

    isAddingSpeaker,

    visualProgress,
  } = useMeetingCreator({
    workspaceId,
    workspacePlan,
    defaultHostId,
    episodeType,
    onClose,
  });

  const presenters = isAddingSpeaker
    ? [eventHost, ...eventPresenters, temporaryEventPresenter]
    : [eventHost, ...eventPresenters];

  const previewTemplateKey = getSlideTemplateKey(
    eventPresenters.length + (isAddingSpeaker ? 1 : 0)
  );

  const { posterSlide } = usePosterSlide(
    {
      ...template,
      settings: template
        ? {
            ...template.settings,
            eventPresenters: presenters,
          }
        : {
            eventPresenters: [],
          },
      scheduledEvent: isCreatingWithDate
        ? getScheduledEvent(scheduledEvent)
        : getScheduledEvent(emptyScheduledEvent),
      title,
    },
    undefined,
    false,
    previewTemplateKey
  );

  const useCreateNewEvent = {
    [episodeTypes.default]: fnCreateNewEvent,
    [episodeTypes.solo]: fnCreateNewSoloInterview,
    [episodeTypes.soloScriptBased]: fnCreateNewSoloInterviewVideoScript,
    [episodeTypes.soloBlogBased]: fnCreateNewBlogBasedSoloInterview,
    [episodeTypes.soloIdeaBased]: fnCreateNewIdeaBasedSoloInterview,
    [episodeTypes.soloProfileBased]: fnCreateProfileBasedSoloInterview,
  };

  const {
    checkpoint,
    checkpointsData,
    isLoading,
    getProgressState,
    handleNextClick,
    handlePreviousClick,
    nextButtonStyling,
    brandKit,
    styleToEnablePreviousButtonAndNextButton,
    styleToDisablePreviousButtonAndDisableNextButton,
    newBlogPost,
    setNewBlogPost,
  } = useCreateNewEvent[episodeType]({
    createNewEvent,
    scheduledEvent: getScheduledEvent(scheduledEvent),
    title,
    videoScript,
    eventSpeakersCount: eventPresenters.length,
  });

  const showPreviousButton = !isEqual(
    Object.values(checkpointsData)[0],
    checkpoint
  );

  const eventSpeakersCount = eventPresenters.length;
  useEffect(() => {
    if (
      (episodeType === episodeTypes.solo ||
        episodeType === episodeTypes.soloBlogBased ||
        episodeType === episodeTypes.soloProfileBased) &&
      eventSpeakersCount === 0
    ) {
      styleToDisablePreviousButtonAndDisableNextButton();
    }

    if (episodeType === episodeTypes.soloBlogBased) {
      if (newBlogPost.length >= 100) {
        styleToEnablePreviousButtonAndNextButton();
      } else {
        styleToDisablePreviousButtonAndDisableNextButton();
      }
    }

    if (episodeType === episodeTypes.soloIdeaBased) {
      if (newBlogPost.length >= 15) {
        styleToEnablePreviousButtonAndNextButton();
      } else {
        styleToDisablePreviousButtonAndDisableNextButton();
      }
    }
  }, [
    newBlogPost,
    episodeType,
    eventSpeakersCount,
    styleToEnablePreviousButtonAndNextButton,
    styleToDisablePreviousButtonAndDisableNextButton,
  ]);

  if (isLoading) {
    return (
      <div className="fixed inset-0 w-full h-full grid place-content-center bg-white">
        <div className="fixed left-[-9999px] top-[-9999px]">
          <ScenePreview
            scene={posterSlide}
            brandKit={brandKit}
            htmlId="poster-id"
            height={900}
            width={1600}
          />
        </div>
        <LogoSpinnerFullCentered />
        {visualProgress ? (
          <div className="font-medium text-sm text-blue-gray text-center">
            {visualProgress}
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <ModalWindow size={Modal.size.fullscreen}>
      <div className="flex flex-col w-full h-full max-h-full">
        <div className="bg-[#303364] h-[165px] w-full p-[5%] flex flex-col justify-between">
          <button onClick={onClose}>
            <LeftArrow />
          </button>
          <div className="flex flex-col font-roboto">
            <span className="text-white font-medium text-2xl">
              {checkpoint?.topics[0]}
            </span>
            <span className="text-[#CACBDB] text-sm">
              {checkpoint?.topics[1]}
            </span>
          </div>
        </div>
        <div className="grow bg-[#FAF6FF] p-[5%] flex flex-col justify-between">
          {Object.keys(checkpointsData).length === 1 ? null : (
            <div className="flex">
              {Object.keys(checkpointsData).map((checkpoint, currentIdx) => (
                <div
                  className={classNames(
                    'grow shrink-0 text-center pb-4 font-montserrat font-medium',
                    getProgressState(checkpoint) === ProgressStates.CURRENT &&
                      'text-[#5C41FF] border-b-[2px] border-[#5C41FF]',
                    getProgressState(checkpoint) === ProgressStates.BEFORE &&
                      'text-[#C4C4C4]',
                    getProgressState(checkpoint) === ProgressStates.AFTER &&
                      'text-[#303364] border-b-[2px] border-[#303364] flex gap-3 justify-center items-center'
                  )}
                  key={currentIdx}
                >
                  {checkpointsData[checkpoint].heading}
                  {getProgressState(checkpoint) === ProgressStates.AFTER && (
                    <CheckMarkIcon />
                  )}
                </div>
              ))}
            </div>
          )}
          <div
            className={classNames(
              'flex flex-col justify-between grow',
              Object.keys(checkpointsData).length > 1 && 'mt-6'
            )}
          >
            <div
              className={classNames(
                'flex w-full ',
                template && checkpoint === MEETING_TOPIC_SCRIPT
                  ? 'h-1/2'
                  : 'h-full mb-5'
              )}
            >
              {template && checkpoint === MEETING_TOPIC && (
                <MobileMeetingTopic
                  title={title}
                  setTitle={setTitle}
                  episodeType={episodeType}
                />
              )}
              {template && checkpoint === MEETING_TOPIC_SCRIPT && (
                <MobileMeetingTopic title={title} setTitle={setTitle} />
              )}
              {template && checkpoint === MEETING_VIDEO_SCRIPT && (
                <MobileMeetingVideoScript
                  videoScript={videoScript}
                  setVideoScript={setVideoScript}
                />
              )}
              {template && checkpoint === SET_BLOG_POST && (
                <MobileAddBlogPost
                  newBlogPost={newBlogPost}
                  setNewBlogPost={setNewBlogPost}
                />
              )}
              {template && checkpoint === SET_IDEA && (
                <MobileAddIdea
                  newBlogPost={newBlogPost}
                  setNewBlogPost={setNewBlogPost}
                />
              )}
            </div>
            <div className="flex gap-5 w-full max-w-full justify-center items-center h-min min-h-min">
              {showPreviousButton && (
                <button
                  onClick={handlePreviousClick}
                  type="button"
                  className="bg-[#2E2E2E] text-white font-montserrat text-2xl rounded-2xl flex items-center w-full gap-5 justify-center"
                >
                  <div className="rotate-180">
                    <DoubleArrowsIcon />
                  </div>
                  Prev
                </button>
              )}
              <button
                onClick={handleNextClick}
                type="button"
                className={classNames(
                  'font-montserrat text-2xl rounded-2xl flex items-center  gap-5 justify-center',
                  nextButtonStyling === buttonFunctionalityClasses.DISABLE
                    ? `bg-[#B9B9B9] text-white`
                    : 'bg-[#5C41FF] text-white',
                  showPreviousButton ? 'w-full' : 'w-1/2'
                )}
              >
                Next
                <DoubleArrowsIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalWindow>
  );
};
