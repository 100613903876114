import React, { useState } from 'react';
import { presenterTypes } from '../../../components/series/settings/EventPresenters';
import { useInterviewMeetingTemplate } from '../../Portal/useInterviewMeetingTemplate';
import { SelectSpeakerDropdown } from '../../Portal/SelectSpeakerDropdown';
import { TaskCardHeader } from './Plan';

export const isSpeakerCompleted = (speaker) => {
  const missingValue = Object.values(speaker || {}).find(
    (value) => typeof value === 'string' && value.length === 0
  );
  return missingValue === undefined ? true : false;
};

export const PlanSpeakerCard = ({
  series,
  localDispatch,
  disableEventPage,
  temporaryEventPresenter,
  setTemporaryEventPresenter,
}) => {
  const { template } = useInterviewMeetingTemplate();
  const { meetingSeriesId, settings, workspace } = series || {};
  const { eventPresenters = [] } = settings || {};
  const fetchedSpeakers = eventPresenters.filter(
    (ep) => ep.type === presenterTypes.SPEAKER
  );

  const [speakers, setSpeakers] = useState(fetchedSpeakers);

  const [isAddingSpeaker, setIsAddingSpeaker] = useState(false);

  return (
    <div>
      <div className="px-5">
        <TaskCardHeader className="mb-6">Set Speaker</TaskCardHeader>
      </div>
      <div className="flex px-5 justify-center items-center gap-[50px]">
        <SelectSpeakerDropdown
          temporaryEventPresenter={temporaryEventPresenter}
          setTemporaryEventPresenter={setTemporaryEventPresenter}
          eventPresenters={speakers}
          setEventPresenters={setSpeakers}
          newMeetingSeriesId={meetingSeriesId}
          series={series}
          localDispatch={localDispatch}
          template={template}
          isAddingSpeaker={isAddingSpeaker}
          setIsAddingSpeaker={setIsAddingSpeaker}
          workspaceId={workspace?.workspaceId}
          disabled={disableEventPage}
        />
      </div>
    </div>
  );
};
