import React from 'react';
import { TextArea } from '../../components/common/TextArea';
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';

export const AddIdea = ({ newBlogPost, setNewBlogPost }) => {
  const userId = useSelector((st) => st.auth?.user?.userId);

  return (
    <div className="w-full h-[225px]">
      <TextArea
        placeholder="Share your idea here (min 15 chars)"
        className="w-full !h-full"
        value={newBlogPost}
        onBlur={(event) => {
          if (event.target.value) {
            mixpanel.track('New Session: Idea Updated', {
              distinct_id: userId,
            });
          }
        }}
        onChange={setNewBlogPost}
      />
    </div>
  );
};

export const MobileAddIdea = ({ newBlogPost, setNewBlogPost }) => {
  const userId = useSelector((st) => st.auth?.user?.userId);

  return (
    <textarea
      placeholder="Share your idea here (min 15 chars)"
      className="h-full w-full p-5 rounded-2xl border-[0.5px] border-[#30336480] placeholder-[#E8E8E8] outline-none font-roboto"
      value={newBlogPost}
      onChange={(event) => setNewBlogPost(event.target.value)}
      onBlur={(event) => {
        if (event.target.value) {
          mixpanel.track('New Session: Idea Updated', {
            distinct_id: userId,
          });
        }
      }}
    />
  );
};
